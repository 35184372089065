import * as z from 'zod'

export const VIEW_PERMS = z.nativeEnum({
  // Start Level 1
  VIEW_ADMIN: 'MENU_ADMIN_VIEW',
  VIEW_DESIGN: 'MENU_DESIGN_VIEW',
  VIEW_FINANCE: 'MENU_FINANCE_VIEW',
  VIEW_FF: 'MENU_FF_VIEW',
  VIEW_SALE: 'MENU_SALE_VIEW',
  VIEW_VENDOR: 'MENU_VENDOR_VIEW',
  // End Level 1

  // Start Level 2
  // Sales views
  VIEW_ORDER: 'MENU_ORDER_VIEW',
  VIEW_LISTING: 'MENU_LISTING_VIEW',
  VIEW_COST_CENTER: 'MENU_COST_CENTER_VIEW',
  VIEW_TRACKING: 'MENU_TRACKING_VIEW',
  VIEW_CASE: 'MENU_CASE_VIEW',
  VIEW_REFUND_REASON: 'MENU_VIEW_REFUND_REASON',
  VIEW_SKU: 'MENU_MAP_SKU_VIEW',
  VIEW_AMZ_STORES: 'MENU_AMZ_STORES',

  // Fulfillment views
  VIEW_QC: 'MENU_QC_VIEW',
  VIEW_SCALE: 'MENU_SCALE_VIEW',
  VIEW_AUTO_FF: 'MENU_AUTO_FF_VIEW',
  VIEW_AS_TOOL: 'MENU_AS_TOOL_VIEW',
  VIEW_LISTING_TYPE: 'MENU_LIST_TYPE_MENU',
  VIEW_FF_REPORT: 'MENU_FF_REPORT_VIEW',

  // Design views
  VIEW_PSD_TASK: 'MENU_PSD_TASK_VIEW',
  VIEW_PNG_TASK: 'MENU_PNG_TASK_VIEW',
  VIEW_GROUP_PRODUCT_TYPE: 'MENU_GROUP_PRODUCT_TYPE_VIEW',
  // Vendor views
  VIEW_PROD_TYPE_DS: 'MENU_PROD_TYPE_DS_MENU',
  // End Level 2
}).enum
